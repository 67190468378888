import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/dashboard2', title: 'Anasayfa', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: 'listings/sitetanimlari', title: 'Site Tanımları', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Başvurular', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/onaybekleyenler', title: 'Onay Bekleyen', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/iptaledilen', title: 'İptal Edilen', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  
    ]
  },
  {
    path: '', title: 'Hasta Üye Paneli', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/uyeler', title: 'Üye Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  
    ]
  },
 
 {
    path: '', title: 'Kurum Paneli', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '', title: 'Hastane Paneli', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/hastaneler', title: 'Hastane Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/faturabekleyenhastane', title: 'Onay B. Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/onaylananfaturahastane', title: 'Onaylanan Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },
      {
        path: '', title: 'Turizm Paneli', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/acenteler', title: 'Turizm Acenteleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

          { path: 'listings/bireyselacenteler', title: 'Bireysel Acenteler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/faturabekleyenacente', title: 'Onay B. Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/onaybekleyenkomisyonlar', title: 'Onay B. Komisyonlar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/onaylanankomisyon', title: 'Onaylanan Komisyonlar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          
          { path: 'listings/onaylananfaturaacente', title: 'Onaylanan Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        ]
      },
      {
        path: '', title: 'Uzman Paneli', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/uzmanlar', title: 'Uzman Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
      }
      
    
      
    ]
  },
 
  {
    path: '', title: 'Teklif/Talep Paneli', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/acikteklifler', title: 'Açık Talepler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      
      { path: 'listings/teklifistekleri', title: 'Teklif İstekleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      
      { path: 'listings/onaylananteklifler', title: 'Onaylanan Teklifler', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,  submenu: [


        {  path: 'listings/kodbekleyen', title: 'Kod Bekleyen', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        {  path: 'listings/onaykodugiden', title: 'Ödeme Onayı Alan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
     

      ] },
      { path: 'listings/bekleyenteklifler', title: 'Bekleyen Teklifler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/reddedilenteklifler', title: 'Reddedilen Teklifler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      
      
    ]
  },

  {
    path: '', title: 'Mesaj Paneli', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '', title: 'Gelen Mesajlar', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,  submenu: [
          { path: 'listings/gelenmesajlar', title: 'Bize Ulaşın', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/siziarayalim', title: 'Size Ulaşalım', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        ]
      },
      { path: 'listings/gidenmesajlar', title: 'Giden Mesajlar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/reddedilenmesajlar', title: 'Reddedilen Mesajlar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '', title: 'Bildirimler', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        {  path: 'listings/gelenbildirimler', title: 'Gelen Bildirimler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        {  path: 'listings/gidenbildirimler', title: 'Giden Bildirimler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
      ]}
    ]
  
  },
  {
    path: '', title: 'Fatura Paneli', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: 'listings/gelenfaturalar', title: 'Gelen Faturalar', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  
        ]
      },
      {
        path: 'listings/gidenfaturalar', title: 'Giden Faturalar', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,  submenu: [
          { path: 'listings/gidenfaturalar', title: 'Bireysel Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/kurumsalfaturalar', title: 'Kurumsal Faturalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        ]
      },
      {
        path: 'listings/toplamhesap', title: 'Toplam Hesap', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
       ]
      },
    ]
  },
  {
    path: '', title: 'Sözleşme Paneli', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/sozlesmebekleyenler', title: 'Sözleşme Bekleyenler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/yuklenensozlesmeler', title: 'Yüklenen Sözleşmeler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    
      
    ]
  },
  {
    path: '', title: 'Komisyon Paneli', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/onaybekleyenkomisyonlar', title: 'Onay Bekleyenler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/odenenkomisyonlar', title: 'Ödenen Komisyonlar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    
      
    ]
  },
  {
    path: '', title: 'Ödemeler', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: 'listings/kurumodemeleri', title: 'Kurum Ödemeleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/bireyselodemeler', title: 'Bireysel Ödemeler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/odemeiptalleri', title: 'Ödeme İptalleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    
    ]
  },
  {
    path: '', title: 'İçerik Yönetimi', icon: 'ft-edit', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: 'listings/sayfalar', title: 'Sayfalar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      {
        
        path: '', title: 'Blog ve Haberler', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/bloglar', title: 'Blog Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/kategoriler', title: 'Kategori Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/alt-kategoriler', title: 'Alt Kategori Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/videolar', title: 'Video Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/top-10', title: 'Top 10 Listesi', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/sik-sorulan-sorular', title: 'Sık Sorulan Sorular', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: 'listings/vitrin', title: 'Vitrin', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ]
      },
      {
        path: '', title: 'Reklamlar', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/onaybekleyenreklamlar', title: 'Onay Bekleyenler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/devamedenler', title: 'Devam Edenler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/bitenler', title: 'Bitenler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Sistem Yönetimi', icon: 'ft-edit', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '', title: 'Tanımlar', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: 'listings/bolumler', title: 'Bölümler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/ulkeler', title: 'Ülkeler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/diller', title: 'Diller', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/parabirimleri', title: 'Para Birimleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/ekranmesajlari', title: 'Ekran Mesajları', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/sorucevaplar', title: 'Soru-Cevap', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
       
          { path: 'listings/sehirler', title: 'Şehirler', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: 'listings/reklampaketleri', title: 'Reklam Paketleri', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },
      { path: 'listings/kullanicilar', title: 'Kullanıcılar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
 
 

  { path: 'logout', title: 'Çıkış Yap', icon: 'ft-life-buoy', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
];