import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionTimerService } from 'session-expiration-alert';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    alertAt = 15;
    startTimer = true;
    subscription: Subscription;

    constructor(private router: Router) {
    }
    increase() {
        this.alertAt++;
      }
    ngOnInit() {

        
                this.subscription = this.router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd)
                )
                .subscribe(() => window.scrollTo(0, 0));
          

      
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}

